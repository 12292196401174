@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');


.header1 {
  text-align: center;
}

.intro {
  display: flex;
  flex-direction: column; /* Ensure the items stack vertically */
  justify-content: center;
  align-items: center; /* Center items horizontally */
  background-image: url('/public/images/house.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

}

.intro-header {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-align: center;
  color: white;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #F8F8FF;
 
}

h1, h2, h3 {
  font-family: 'Roboto', sans-serif;
}

p {
  font-family: 'Roboto', sans-serif;
}

.titles {
  padding-left: 10px;
}


.intro {
  height: 250px;
}

/* New section wrapper */
.section-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  margin-right: 50px;
  margin-left: 50px;
}

.residential, .commercial, .renovations {
  flex: 1;
  background: linear-gradient(to top, rgba(129, 142, 135, 1), rgba(129, 142, 135, 0.1));
  color: white;
  margin: 10px;
  position: relative; /* Added for the blue line */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4); /* Shadow effect */
  border-radius: 15px; /* Optional: rounds the corners */
  flex-basis: calc(33% - 5px); /* 3 items in a row with margin */
  max-width: 400px; /* Optional: set a maximum width */
  min-width: 250px; /* Ensures a minimum width for smaller screens */
  height: auto; /* Let height adjust based on content */
  display: flex;
  flex-direction: column; /* To ensure proper alignment of content inside */
  align-items: center;
}

.home-improve {
  border-top-left-radius: 15px; /* Optional: rounds the corners */
  border-top-right-radius: 15px; /* Optional: rounds the corners */
  width: 100%;
  height: auto; /* Maintain aspect ratio */
}

/* Adjust image size within the boxes */
.residential img, .commercial img, .renovations img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.square {
  text-align: center;
  width: 100%;
}

.res, .com, .home {
  text-align: center;
  font-size: 18px;
  padding: 5px;
}


.request {
  height: 300px;
  text-align: center;
}

.divider, .projects {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}

.back-color {
  background-color: #003153;
  height: auto;
  width: auto;
}

/*************** Menu *********************/

.menu {
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
}

.logo-and-title {
  display: flex;
  align-items: center;
}

.otherlogo {
  margin-right: 40px;
  height: 50px;
  width: auto;
}

/* Styles for Desktop Menu Links */
.menu-links {
  display: flex; /* This will align menu items in a row */
  align-items: center; /* Ensure vertical alignment */
}

.menu-links a {
  font-family: 'Roboto', sans-serif;
  margin-right: 24px;
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;

  
}

.menu-links a:last-child {
  margin-right: 0;

}

.link-color{
 color: black;
}

.contact-button {
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  height: 10px;  /* Set height */
  width: 60px;  /* Set width */
  display: flex;  /* Ensure flex for centering */
  align-items: center;  /* Center vertically */
  justify-content: center;  /* Center horizontally */
}

.contact-button:hover {
  background-color: #0056b3;
}

.phone {
  font-weight: bold;
  font-size: 28px;
}
/**************************************************************/

ul {
  list-style-type: none;
  font-size: 20px;
  padding: 0;
  margin: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.8s ease-out forwards;
}

.request-button {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.request-button:hover {
  background-color: #0056b3;
}
/************************************************/
.foot {
  background: linear-gradient(to top, rgba(129, 142, 135, 1), rgba(129, 142, 135, 0));
  text-align: center;
  height: 200px;
  padding: 16px;
}

.contact, .quote {
  text-align: center;
  padding: 20px;
}

.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh; /* Adjust as needed */
  background-image: url('/public/images/contact.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.q {
  text-align: left;
}

.contact-box {
  margin-top: 20px;
  margin-left: 20px;
  background-color: white;
  text-align: center;
  width: 400px;
  height: 420px;
  padding: 0px 16px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow around the box */
  position: relative; /* Needed for positioning the pseudo-element for underline */
}

.contact-box::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  background-color: #A1C4FD; /* Blue underline */
  border-radius: 2px; /* Optional: for slightly rounded corners on the underline */
}

.form-group {
  text-align: left;
  padding: 5px;
}

input[type="text"], input[type="email"], input[type="tel"], textarea {
  border: 1px solid #9d9d9d;
  border-radius: 5px;
  width: 90%;
  padding: 10px;
}

button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

button[type="submit"]:focus {
  outline: none;
  box-shadow: 0 0 5px #007bff;
}

.thank-you-message {
  text-align: center;
  margin-top: 50px;
}

.projects {
  background: linear-gradient(to top, rgba(129, 142, 135, 1), rgba(129, 142, 135, 0.1));
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-bottom: 120px;
  width: 100%;
}

.project {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white; /* White background */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4); /* Adds a subtle shadow around the box */
  border-radius: 5px; /* Optional: rounds the corners */
  padding: 20px;
  margin-bottom: 20px; /* Space below each project */
  width: 100%;
  max-width: 800px;
}

.project:nth-child(even) {
  flex-direction: row-reverse; /* Reverse the order for even projects */
}

.project-image-container {
  flex: 1;
  max-width: 50%;
  padding: 10px;
}

.project-image-container img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  border-radius: 5px;
}

.project-description {
  font-family: 'Roboto', sans-serif;
  flex: 1;
  padding: 10px;
  width: max-content;
  font-weight: bold;
  font-size: larger;
  text-align: center;
  color: #4A4A4A;
}

.project-image-container {
  width: 100%; /* Adjust as needed */
  height: 450px; /* Adjust as needed */
  position: relative;
  overflow: hidden; /* This will ensure no part of the image spills out of the container */
}

.project-image-container img {
  width: 100%;  /* Makes the image take up the full width of the container */
  height: 100%; /* Makes the image take up the full height of the container */
  object-fit: cover; /* This resizes the image to cover the container while maintaining the aspect ratio */
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}


.after-image {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.project-image-container:hover .after-image {
  opacity: 1;
}

footer {
  background-color: #333333;
  color: white;
  text-align: center;
  padding: 10px;
  width: 100%;
  height: 100px;
  position: fixed;
  bottom: 0;
  left: 0;
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: auto;
}

.foot-divider {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 5px;
}

.socials {
  text-align: center;
  width: 75%;
  border-radius: 15px;
}

.socials img {
  margin: 0 10px;
  width: auto;
  height: 35px;
}

.company {
  width: 25%;
  text-align: left;
  border-radius: 15px;
}

.social-link {
  text-decoration: none;
  color: white;
}

.hamburger {
  display: none; /* Hidden by default */
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 10px;
}

.hamburger div {
  width: 30px;
  height: 3px;
  background-color: #333;
  margin: 5px 0;
}

.otherlogo2 {
  display: none;
}

.about-page {
  align-content: center;
}

.quote {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  margin: 16px;
  width: 90%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4); /* Shadow effect */
  border-radius: 15px; /* Optional: rounds the corners */
}

.Sal-photo {
  width: 300px;
  height: auto; /* Maintain aspect ratio */
}

.intro-about {
  text-align: center;
  color: #333333;
}

.titles {
  text-align: center;
}








/* Mobile specific styles */
@media only screen and (max-width: 600px) {
  .hamburger {
    display: block; /* Show hamburger icon on small screens */
  }

  .menu-links {
    display: none; /* Hide the menu links by default */
    position: absolute;
    top: 50px; /* Position it just below the menu bar */
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
    flex-direction: column;
    z-index: 1000; /* Ensure it's above other content */
  }

  .menu-links.open {
    display: flex; /* Show the menu links when open */
  }

  .menu-links a {
    padding: 10px 20px; /* Space out the links */
    font-size: 18px; /* Increase font size for touch targets */
    text-align: left;
  }

  .otherlogo2 {
    display: none; /* Hide the secondary logo in mobile view */
  }

  .desktop-menu {
    display: none; /* Hide desktop menu on mobile */
  }

  .section-wrapper {
    flex-direction: column;
    margin-right: 60px;
  }

  .residential, .commercial, .renovations {
    flex-basis: calc(100% - 20px); /* 1 item in a row with margin */
  }

  .intro {
    height: auto; /* Adjusts height for smaller screens */
    padding: 20px;
  }

  .contact, .quote, .project1, .project2, .project3 {
    width: 100%;
    padding: 10px;
  }

  .divider, .projects {
    flex-direction: column;
    height: auto;
  }

  .otherlogo {
    margin-right: 10px;
    height: 40px;
  }

  .should {
    display: none;
  }

  .contact-box, .p1, .p2, .p3 {
    width: auto;
    margin: 10px;
  }

  .logo-and-title, .menu-links {
    width: 100%;
    justify-content: center;
    text-align: center;
  }

  .menu-links a {
    margin-right: 8px;
    font-size: 14px;
  }

  /* Adjust the footer styling for mobile */
  footer {
    height: 50px; /* Make the footer slimmer */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px; /* Adjust padding as needed */
    width: 100%;
  }

  /* Adjust the logo size in the footer */
  .logo {
    padding-top: 5px;
    height: 40px; /* Adjust the logo size for mobile */
    width: auto;
    order: 1; /* Ensure logo is on the left */
  }

  .socials {
    display: flex;
    flex-direction: row; /* Align icons horizontally */
    justify-content: center; /* Center icons */
    align-items: center;
    width: auto;
    order: 2; /* Ensure socials are on the right */
    padding-left: 200px;
  }

  /* Adjust the social media icons */
  .socials img {
    display: block;
    height: 25px; /* Adjust the size of the icons */
    width: auto;
    margin-left: 10x; /* Add some space between icons */
    margin-right: 10px; /* Add some space between icons */
  }

  /* Hide elements in the footer not needed on mobile */
  .company {
    display: none;
  }

  .find {
    display: none;
  }

  /* Center the logo and social media icons */
  .socials, .logo {
    margin: 0 auto;
  }

  .phone {
    text-align: center;
    justify-content: center;
    font-size: 24px;
  }

  .titles {
    text-align: center;
    color: #333333;
  }

  .projects {
    background: linear-gradient(to top, rgba(129, 142, 135, 1), rgba(129, 142, 135, 0.1));
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    width: 100%;
    padding-bottom: 50px;
  }

  .project {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white; /* White background */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4); /* Adds a subtle shadow around the box */
    border-radius: 5px; /* Optional: rounds the corners */
    padding: 10px;
    margin-bottom: 20px; /* Space below each project */
    width: 100%;
    max-width: 300px;
  }

  .project-description {
    font-size: medium;

  }

  .project-image-container {
    height: 200px; /* Adjusted height for smaller screens */
  }

  .project-image-container img {
    height: 100%; /* Maintain aspect ratio */
  }


  .contact-button {
    background-color: white;
    color: #007bff;
    padding: 2px;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    display: flex;  /* Ensure flex for centering */
    align-items: center;  /* Center vertically */
    justify-content: center;  /* Center horizontally */
  }

  .contact-button:hover {
    background-color: white;
  }

  .quote {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 20px;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4); /* Shadow effect */
    border-radius: 15px; /* Optional: rounds the corners */
  }
  
  .Sal-photo {
    width: 200px;
    height: auto; /* Maintain aspect ratio */
  }
}

/* Desktop specific styles */
@media only screen and (min-width: 601px) {
    /* Styles specific to desktop */
  /* Hide the hamburger menu on desktop */
  .residential, .commercial, .renovations {
    flex-basis: calc(33.333% - 20px); /* 3 items in a row with margin */
  }

  .hamburger {
    display: none;
  }

  /* Ensure full width links are not applied on desktop */
  .menu-links a {
    display: flex;
  }
}